import * as ethers from 'ethers';
import axios from 'axios';
import { galaApiUrl, localApiUrl } from '../config';
export const createNewWallet = async () => {
    const newWallet = ethers.Wallet.createRandom();
    console.log("Public key:", newWallet.publicKey);
    console.log("Private key:", newWallet.privateKey);
    return newWallet;
}
export const importNewWallet = async (mnemonic) => {
    if (!ethers.Mnemonic.isValidMnemonic(mnemonic)) {
        throw new Error('Invalid wallet mnemonic phrase !')
    }
    const newWallet = ethers.Wallet.fromPhrase(mnemonic);
    console.log(newWallet);
    console.log("Public key:", newWallet.publicKey);
    console.log("Private key:", newWallet.privateKey);
    console.log("mnemonic :", newWallet.mnemonic.phrase);
    return newWallet;
}

export const createHeadlessWallet = async (publicKey) => {
    try {
        const response = await axios.post(`${galaApiUrl}/v1/CreateHeadlessWallet`, {
            publicKey
        }, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        console.log(response.data);
        return response.data;
    } catch (error) {
        console.log(error.response);
        throw new Error('Something went wrong !')
    }
}

export const fetchBalances = async (walletAddress) => {
    try {
        const response = await axios.post(`${localApiUrl}/wallet/getBalances`, {
            walletAddress
        });
        return response.data.data;
    } catch (error) {
        console.log(error.response);
        throw new Error('Something went wrong !')
    }
}
export const transferToken = async ({
    signerPublicKey,
    privateKey,
    quantity,
    from,
    to
}) => {
    try {
        const response = await axios.post(`${localApiUrl}/wallet/TransferToken`, {
            signerPublicKey,
            tokenInstance: {
                collection: "GALA",
                category: "Unit",
                type: "none",
                additionalKey: "none",
                instance: "0"
            },
            privateKey,
            quantity,
            from,
            to
        });
        return response.data.data;
    } catch (error) {
        console.log(error.response);
        throw new Error('Something went wrong !')
    }
}
export const getHeadlessWallet = async (walletAddress) => {
    try {
        const response = await axios.post(`${localApiUrl}/wallet/getPublicKey`, {
            walletAddress
        });
        console.log(response.data.data);
        return response.data.data;
    } catch (error) {
        console.log(error.response);
        throw new Error('Something went wrong !')
    }
}