import './App.css';
import React from 'react';
import { createHeadlessWallet, createNewWallet, fetchBalances, getHeadlessWallet, importNewWallet, transferToken } from './utils/ethers';
function App() {
  const [wallet, setWallet] = React.useState();
  const [balance, setBalance] = React.useState(0);
  const [isImporting, setIsImporting] = React.useState(false);
  const [isCreating, setIsCreating] = React.useState(false);
  const [isFetching, setIsFetching] = React.useState(false);
  const [isTransfering, setIsTransfering] = React.useState(false);
  const [headlessWallet, setHeadlessWallet] = React.useState();
  const handleCreateWallet = () => {
    setIsCreating(true)
    createNewWallet().then((wallet) => {
      setWallet(wallet);
      createHeadlessWallet(wallet.publicKey).then((wallet) => {
        setHeadlessWallet(wallet);
        setIsCreating(false);
        setIsFetching(true)
        fetchBalances(wallet?.walletAddress).then((response) => {
          setBalance(response?.Data?.[0]?.quantity || 0);
          setIsFetching(false);
        }).catch((error) => {
          setIsFetching(false);
        })
      }).catch((error) => {
        setIsCreating(false);
        alert(error?.message);
      })
    }).catch((error) => {
      setIsCreating(false);
      alert(error?.message);
    })
  }
  console.log('wallet => ', wallet);
  console.log('headlessWallet => ', headlessWallet);
  const handleImportWallet = () => {
    const walletAddress = prompt("Enter Your Wallet Address :");
    const mnemonic = prompt("Enter Your Wallet mnemonic phrase :");
    console.log('mnemonic', mnemonic);
    setIsImporting(true);
    importNewWallet(mnemonic).then((wallet) => {
      setWallet(wallet);
      getHeadlessWallet(walletAddress).then((wallet) => {
        setHeadlessWallet(wallet);
        setIsImporting(false);
        setIsFetching(true)
        fetchBalances(wallet?.walletAddress).then((response) => {
          setBalance(response?.Data?.[0]?.quantity || 0);
          setIsFetching(false);
        }).catch((error) => {
          setIsFetching(false);
        })
      }).catch((error) => {
        setIsImporting(false);
        alert(error?.message);
      })
    }).catch((error) => {
      setIsImporting(false);
      alert(error?.message);
    })
  }

  const handleTransferToken = () => {
    const to = prompt("Enter Wallet Address To Send GALA :");
    const quantity = prompt("Enter GALA Quantity :");
    setIsTransfering(true);
    transferToken({
      signerPublicKey: headlessWallet?.publicKey,
      from:headlessWallet?.walletAddress,
      privateKey: wallet?.privateKey,
      quantity,
      to,
    }).then(() => {
      setIsTransfering(false);
      setIsFetching(true)
      fetchBalances(wallet?.walletAddress).then((response) => {
        setBalance(response?.Data?.[0]?.quantity || 0);
        setIsFetching(false);
      }).catch((error) => {
        setIsFetching(false);
      })
    }).catch((error) => {
      setIsTransfering(false);
      alert(error?.message);
    })
  }
  return (
    <div className='wallet-element'>
      <div className='wallet-block'>
        <h2>Say hello to your Gala wallet</h2>
        <div>
          {
            headlessWallet ?
              <>
                <p>Your headless wallet privateKey : {wallet?.privateKey}</p>
                <p>Your headless wallet mnemonic phrase : {wallet?.mnemonic?.phrase}</p>
                <p>Your headless wallet walletAddress : {headlessWallet?.walletAddress}</p>
                <p>Your headless wallet publicKey : {headlessWallet?.publicKey}</p>
                <p>Your headless wallet balance : {isFetching ? "..." : `${balance} GALA`}</p>
                <button disabled={isFetching} onClick={() => {
                  setIsFetching(true);
                  fetchBalances(headlessWallet?.walletAddress).then((response) => {
                    setBalance(response?.Data?.[0]?.quantity || 0)
                    setIsFetching(false);
                  })
                }}> Refresh Balance</button>
                <button style={{ marginLeft: '10px' }} disabled={isTransfering} onClick={handleTransferToken}>Transfer Token</button>
              </>
              :
              <>
                {!isImporting && <button className='wallet-btn btn-green' disabled={isCreating} onClick={handleCreateWallet}>
                  {isCreating ? "Creating a new wallet ..." : 'Create a new wallet'}
                </button>}
                {!isCreating && <button className='wallet-btn btn-blue' onClick={handleImportWallet}>
                  {isImporting ? "Importing an existing wallet ..." : 'Import an existing wallet'}
                </button>}
              </>
          }
        </div>
      </div>
    </div>
  );
}

export default App;
